import { Controller } from "stimulus";
import Hls from "hls.js";
import Plyr from "plyr";
import { SupercastTimeUpdateEvent } from "utils/events";

export default class extends Controller {
  static targets = [
    "controlsTemplate",
    "videoContainer",
    "popoutPlaceholder",
    "castPlaceholder",
    "castButton"
  ]

  static values = {
    timestamp: Number,
    markers: Array,
    episodeId: Number,
    floating: Boolean,
    mediaMetadata: Object
  }

  async connect() {
    this.video = this.element.querySelector('video');

    this.initializePlayer();
    this.boundVisitPopOut = this.visitPopOut.bind(this);
    this.boundReadyToPlay = this.readyToPlay.bind(this);

    if (this.hasFloatingValue && !this.floatingValue) {
      window.addEventListener("turbo:load", this.boundVisitPopOut);
    }

    window.addEventListener("supercast-timeupdate", this.onTimeUpdate.bind(this));

    if (!this.video.src) {
      this.initializeVideo();
      if (this.hasCastButtonTarget) {
        this.initChromecast();
      }
    }
  }

  async initializeVideo() {
    this.hls = new Hls();

    this.video.addEventListener('canplay', this.boundReadyToPlay);

    // Try to fetch the hls playlist url, if we can't find it then fall back to the original
    this.hlsUrl = this.element.getAttribute('data-playlist-url');
    this.originalUrl = this.element.getAttribute('data-original-url');

    const response = await fetch(this.hlsUrl, { method: 'HEAD' });

    if (response.ok) {
      this.loadHlsUrl();
    } else {
      this.loadOriginalUrl();
    }
  }

  loadHlsUrl() {
    /**
     * A note on this conditional: We check if airplay is supported because HLS.js
     * will populate the source of the video with blobs that are not supported by
     * Airplay. Every browser that IS capable of Airplay will support HLS natively though,
     * so we don't need HLS.js in that case.
     *
     * Why not just look for native HLS support and prioritize that? Because some versions
     * of Android have shaky support for HLS, so depsite saying they support it, it causes
     * freezing issues when seeking using native HLS (this is inconsistent so if you're changing
     * this code, test extensively on Android).
     */
    if (Hls.isSupported() && !window.WebKitPlaybackTargetAvailabilityEvent) {
      var video = this.video;
      this.hls.loadSource(this.hlsUrl);
      this.hls.attachMedia(video);
    } else if (this.video.canPlayType('application/vnd.apple.mpegurl')) {
      const url = this.element.getAttribute('data-playlist-url');
      this.video.src = this.hlsUrl;
    } else {
      this.loadOriginalUrl();
    }
  }

  loadOriginalUrl() {
    this.video.src = this.originalUrl;
  }

  initializePlayer() {
    this.player = new Plyr(this.video, {
      autoplay: true,
      mediaMetadata: this.mediaMetadataValue,
      seekTime: 15,
      fullscreen: { enabled: true, iosNative: true },
      volume: 1,
      controls: [
        'play-large', // The large play button in the center
        'play', // Play/pause playback
        'progress', // The progress bar and scrubber for playback and buffering
        'current-time', // The current time of playback
        'duration', // The full duration of the media
        'mute', // Toggle mute
        'airplay', // Airplay (currently Safari only)
        'fullscreen', // Toggle fullscreen
      ]
    });

    this.injectControls();

    this.player.on("enterfullscreen", () => {
      screen.orientation.lock("landscape");
    });

    this.player.on("exitfullscreen", () => {
      screen.orientation.unlock();
    });

    this.video.addEventListener("webkitfullscreenchange", () => {
      if (this.video.webkitDisplayingFullscreen) {
        screen.orientation.lock("landscape");
      } else {
        screen.orientation.unlock();
      }
    });
  }

  injectControls() {
    const controls = document.importNode(this.controlsTemplateTarget.content, true);
    this.player.elements.controls.appendChild(controls);
  };

  updateTime() {
    const event = new SupercastTimeUpdateEvent({
      itemId: this.element.getAttribute('data-item-id'),
      itemType: this.element.getAttribute('data-item-type'),
      currentTime: this.player.currentTime,
      duration: this.player.duration,
      isPlaying: !this.player.paused,
      mediaType: "video"
    });

    this.updateMediaSessionPlaybackState();

    window.dispatchEvent(event);
  }

  loadFull() {
    window.Turbo.visit(`/subscriber_v2/${this.element.getAttribute('data-item-type').toLowerCase()}s/${this.element.getAttribute('data-item-id')}?timestamp=${this.player.currentTime}`);
    this.close();
  }

  loadFullOrClose() {
    if (window.location.pathname == this.thisVideoPath()) {
      this.loadFull();
    } else {
      this.close();
    }
  }

  popOut() {
    window.Turbo.visit(
      `/subscriber_v2/${this.element.getAttribute('data-item-type').toLowerCase()}s/${this.element.getAttribute('data-item-id')}/play?timestamp=${this.player.currentTime}`,
      { frame: "floating-player" }
    );
    this.player.pause();
    this.popoutPlaceholderTarget.classList.remove("hidden");
    this.videoContainerTarget.classList.add("hidden");
  }

  visitPopOut(event) {
    if (this.hasFloatingValue && this.floatingValue) {
      return;
    }
    if (this.player.paused) {
      return;
    }

    if (!window.location.pathname.includes("/videos") && !window.location.pathname.includes("/episodes")) {
      this.popOut();
      window.removeEventListener("turbo:load", this.boundVisitPopOut);
    }
  }

  thisVideoPath() {
    return `/subscriber_v2/${this.element.getAttribute('data-item-type').toLowerCase()}s/${this.element.getAttribute('data-item-id')}`;
  }

  close() {
    this.element.remove();
  }

  async toggleCast() {
    if (this.isCasting) {
      this.stopCasting();
    } else {
      this.cast();
    }
  }

  async cast() {
    const session = await this.getChromeCastSession(true);
    const mediaInfo = new chrome.cast.media.MediaInfo(this.originalUrl, "video/mp4");
    const request = new chrome.cast.media.LoadRequest(mediaInfo);
    request.autoplay = true;
    session.loadMedia(request);
    this.isCasting = true;

    this.castPlaceholderTarget.classList.remove("hidden");
    this.videoContainerTarget.classList.add("hidden");
  }

  async stopCasting() {
    const castSession = await this.getChromeCastSession(false);
    if (castSession) {
      castSession.endSession(true);
    }
    this.isCasting = false;
    this.castPlaceholderTarget.classList.add("hidden");
    this.videoContainerTarget.classList.remove("hidden");
  }

  async readyToPlay() {
    this.video.removeEventListener('canplay', this.boundReadyToPlay);
    if (this.hasTimestampValue && this.timestampValue > 0) {
      this.video.currentTime = this.timestampValue;
    } else {
      const listenData = await window.playbackDb.getPlaybackData(this.element.getAttribute('data-item-type'), this.element.getAttribute('data-item-id'));
      if (listenData) {
        this.video.currentTime = listenData.currentTime;
      }
    }

    this.player.on("timeupdate", this.updateTime.bind(this));
    this.video.play();
  }

  updateMediaSessionPlaybackState() {
    if (!("mediaSession" in navigator)) {
      return;
    }

    navigator.mediaSession.setPositionState({
      duration: this.player.duration,
      playbackRate: this.player.speed,
      position: this.player.currentTime
    });

    navigator.mediaSession.playbackState = this.player.paused ? "paused" : "playing";

  }

  initChromecast() {
    if (!window.chrome || !window.chrome.cast) { return; }
    const loadCastInterval = setInterval(() => {
      if (window.chrome.cast.isAvailable) {
        clearInterval(loadCastInterval);
        this.initializeCastApi();
      }
    }, 1000);
  }

  async initializeCastApi() {
    this.castContext = cast.framework.CastContext.getInstance();
    this.castContext.setOptions({
      receiverApplicationId: chrome.cast.media.DEFAULT_MEDIA_RECEIVER_APP_ID,
      autoJoinPolicy: chrome.cast.AutoJoinPolicy.ORIGIN_SCOPED
    });
    this.castButtonTarget.classList.remove("hidden");
  }

  async getChromeCastSession(initializeNew) {
    const castSession = this.castContext.getCurrentSession();
    if (castSession) {
      return castSession;
    } else if (initializeNew) {
      await this.castContext.requestSession();
      return this.castContext.getCurrentSession();
    } else {
      return null;
    }
  }

  onTimeUpdate(event) {
    if (event.detail.mediaType === "audio" && this.hasFloatingValue && this.floatingValue) {
      this.close();
    }
  }
}